<template>
    <div class="row mx-0 h-100">
        <div class="col-xl-6 px-0 h-100 overflow-auto custom-scroll border-right border-left">
            <div class="p-2 f-15 overflow-auto custom-scroll chat-mensajes" :class="rutaCedis ? 'height-cedis' : $route.name == 'admin.cedis.combos-kits' ? 'height-kits' : 'height-productos'">
                <div v-infinite-scroll="lista_productos" infinite-scroll-disabled="busy" infinite-scroll-immediate-check="false" infinite-scroll-distance="20">
                    <div class="row mx-0 justify-center">
                        <card-cardPromocion1
                        v-for="(prod, idx) in productosFiltrados"
                        :key="`prod-${idx}`"
                        :producto="prod"
                        :activo="producto_activo"
                        :caso="caso"
                        class="mx-1"
                        @accion="detalle_producto"
                        @visible="cambio_estado"
                        />
                    </div>
                    <contentLoader v-show="loading" />
                </div>
                <div v-if="categoria === null" class="row h-100 mx-0 align-content-center justify-center">
                    <div class="col-9 text-center">
                        <div class="row mx-0 justify-center f-60">
                            <img src="/img/no-imagen/click.svg" alt="" style="width:115px;height:115px;" />
                        </div>
                        <p class="mb-0 text-muted2 f-17 f-600">Selecciona una categoria del panel de la izquierda. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-5 px-0 h-100">
            <detalle />
        </div>
    </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import Productos from "~/services/productos/productosAdmin"
import {mapGetters} from 'vuex'
export default {
    directives: {infiniteScroll},
    components: {
        detalle: () => import('./detalle'),
    },
    props:{
        caso: {
            type: Number,
            default: 1
        },
        categoria:{
            type: Number,
            default: 0
        },
        kit:{
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            busy: false,
            porcentaje: '',
            verDetalle: 0,
            producto_activo:0,
            info_categoria:{},
            loading:false,
            page:0,
            buscar:'',
            bandera:false,
            producto: [
                {
                    borde: 1,
                    cant_carrito: 4,
                    cant_cedis_productos_activos: 1,
                    cantidad_minima: "1.000",
                    color: 1,
                    descuento: 0,
                    estado: 0,
                    foto: "productos/1748041699601c3d5ad83f43.297171660.885759001612463450.jpeg",
                    has_promocion: true,
                    id: 7,
                    imagen: "https://storage.googleapis.com/leeche-prueba/productos/1748041699601c3d5ad83f43.297171660.885759001612463450.jpeg",
                    nombre: "lentejas cedis mexico",
                    posicion: 1,
                    precio: "9999995.000",
                    presentacion: "350 gr",
                    sku: "6",
                    texto: "DESTACADO",
                    tipo: 5,
                    unidad: "gr"
                }
            ]
        }
    },
    computed:{
        ...mapGetters({
            productos: 'productos/productos/productos',
            query: 'productos/productos/query',
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar',
            id_moneda:'cedis/id_moneda'
        }),
        rutaCedis(){
            return this.$route.name == 'admin.cedis.productos' || this.$route.name == 'tendero.productos'
        },
        productosFiltrados(){
            if(this.buscar !== ''){ // cuando funcinaba el buscador en esa tab
                let productosCopy = [...this.productos]
                let string = this.buscar

                var productosFormat = productosCopy.map(el => ({value: `${el.nombre} ${el.presentacion}`, id: el.id}))
                let divisiones = string.split(' ')
                var todosRes = []
                var coinciden = []
                divisiones.forEach(e =>{
                    let filtradosNombre = productosFormat.filter(el => {
                        return el.value.toLowerCase().includes(e.toLowerCase())
                    })
                    todosRes = [...todosRes,...filtradosNombre];
                    coinciden.push(filtradosNombre.map(e => e.id))
                })
                let unicos = coinciden.reduce((acc, el) => {
                    el.forEach(elm => {
                        if(acc[elm]){
                            acc[elm] += 1
                        } else {
                            acc[elm] = 1
                        }
                    })
                    return acc
                }, {})
                let prueba = []

                for (let x in unicos){
                    if(unicos[x] == divisiones.length){
                        prueba.push(x)
                    }
                }

                const idsProductos = prueba.map(e => parseInt(e))
                const final = productosCopy.filter(el => {
                    return idsProductos.includes(el.id)
                })
                return final
                // return this.productos.filter(e => {
                //     return e.nombre.toLowerCase().includes(this.buscar.toLowerCase())
                // })
            }else{
                let uniqBy = _.uniqBy(this.productos, 'id')
                return uniqBy
            }
        }
    },
    watch:{
        categoria(val){
            if(val){
                this.page = 0
                this.$store.commit('productos/productos/set_id_producto', null)
                this.$store.commit('productos/productos/set_is_kit', false)
                this.$store.commit('productos/productos/vaciar_productos')
                this.lista_productos()
            }else{
                this.info_categoria = {}
                this.$store.commit('productos/productos/set_id_producto', null)
                this.$store.commit('productos/productos/set_is_kit', false)
                this.$store.commit('productos/productos/vaciar_productos')
            }
        },
        id_cedis_navbar(val){
            if(this.categoria){
                this.page = 0
                this.$store.commit('productos/productos/set_id_producto', null)
                this.$store.commit('productos/productos/set_is_kit', false)
                this.$store.commit('productos/productos/vaciar_productos')
                this.lista_productos()
            }else{
                this.info_categoria = {}
                this.$store.commit('productos/productos/set_id_producto', null)
                this.$store.commit('productos/productos/set_is_kit', false)
                this.$store.commit('productos/productos/vaciar_productos')
            }
        }
    },
    // mounted(){
    //     if(this.id_cedi_ruta){
    //         this.$store.commit('cedis/set_id_cedis', this.id_cedi_ruta)
    //     }
    // },
    methods: {
        async lista_productos(){
            try {
                if(this.categoria == null){
                    console.log('Por favor seleccione una categoria');
                    return
                }
                this.page = this.page + 1
                this.loading = true
                this.busy = true;

                let params = {}
                if(this.bandera){
                    this.page = 0

                    params.categoria =  this.categoria,
                    params.page = 1,
                    params.query = this.buscar


                }else{
                    params.categoria =  this.categoria,
                    params.page = this.page,
                    params.query = this.query
                }
                params.id_cedis= this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis_navbar
                params.caso = this.caso
                params.kit = this.kit

                const {data} = await Productos.listar(params)

                this.$store.commit('productos/productos/set_productos', data.productos.data)

                if(data.productos.current_page === data.productos.last_page){
                    this.busy = true;
                }else{
                    this.busy = false;
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        // async buscar_productos(query){
        //     if(query.length > 2){
        //         const busqueda = async() => {
        //             this.bandera = true
        //             this.$store.commit('productos/productos/vaciar_productos')
        //             this.lista_productos()
        //         }
        //         await this.delay(busqueda)
        //     }else if(query.length === 0){
        //         this.bandera = false
        //         this.$store.commit('productos/productos/vaciar_productos')
        //         this.lista_productos()
        //     }
        // },
        async productos_busqueda(){
            try {
                if(this.categoria == null){
                    console.log('Por favor seleccione una categoria');
                    return
                }
                this.page = this.page + 1
                this.loading = true
                this.busy = true;
                let params = {
                    categoria: this.categoria,
                    page:this.page,
                    query:this.query
                }
                const {data} = await Productos.listar(params)


                this.$store.commit('productos/productos/set_productos', data.productos.data)

                if(data.productos.current_page === data.productos.last_page){
                    this.busy = true;
                }else{
                    this.busy = false;
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },

        detalle_producto(producto){
            this.producto_activo = producto.id
            this.$store.commit('productos/productos/set_id_producto', producto.id)
            this.$store.commit('productos/productos/set_is_kit', (producto.kit==1))
        },
        async cambio_estado(id){
            try {
                let params = {
                    cedis:this.$route.name == 'admin.cedis.productos',
                    id_cedis: this.id_cedi_ruta
                }
                const {data} = await Productos.cambio_estado(id, params)
                this.notificacion('Mensaje','Estado actualizado','success')
                let producto = this.productos.find(p=>p.id === id)
                producto.estado = !producto.estado
            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}

/* Tamaño contenedor Cedis */
    .height-cedis{ height: calc(100vh - 194px); }
/* Tamaño contenedor Productos */
    .height-productos{
        height: calc(100vh - 103px);
    }
    .height-kits{
        height: calc(100vh - 192px);
    }
</style>
